<template>
    <v-card>
        <v-toolbar fixed dark color="blue" class="darken-1">
            <v-toolbar-title>
                {{$t('customer_form')}}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
                <v-btn dark text @click="dialogueClose">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
            <v-form>
                <v-container>

                    <v-card class="my-12">
                        <v-toolbar class="darken-1" color="blue" dark fixed>
                            <v-toolbar-title>
                                {{$t('find_person_id')}}
                            </v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-select
                                            :items="['Passport','Citizen']"
                                            :label="$t('type')"
                                              dense
                                              outlined
                                            v-model="trackType"
                                    >
                                    </v-select>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                            :label="$t('enter_your_id')"
                                                  dense
                                              outlined
                                            v-model="trackID"
                                    ></v-text-field>
                                </v-col>
                                <v-col class="d-flex" cols="12" sm="12">
                                    <v-spacer></v-spacer>
                                    <v-btn @click="getUserData" class="mx-2" color="primary" dark small>
                                        <v-icon dark> search</v-icon>
                                        {{$t('search')}}
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>

                    <v-row>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.fullName"
                                          :label="$t('full_name')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>

                        <!--<v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.firstName"
                                          label="First Name "
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.lastName"
                                          label="Last Name"
                                          required></v-text-field>
                        </v-col>-->

                        <v-col cols="12"
                               sm="6"
                               md="4"
                               style="align-content:center">
                            <v-radio-group v-model="customerData.gender" row :label="$t('gender')">
                                <v-radio :label="$t('male')" value="Male"></v-radio>
                                <v-radio :label="$t('female')" value="Female"></v-radio>
                                <v-radio :label="$t('others')" value="Other"></v-radio>
                            </v-radio-group>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.dateOfBirths"
                                                  :label="$t('dob')"
                                                  clearable
                                                  dense
                                              outlined
                                                  prepend-inner-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.dateOfBirths">

                                </v-date-picker>
                            </v-menu>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.nationality"
                                          :label="$t('nationality')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete v-model="customerData.country"
                                            :items="countries"
                                            :label="$t('country')"
                                            dense
                                              outlined
                                            clearable
                                            single-line
                                            item-text="value"
                                            item-value="id"
                                            @change="getState"></v-autocomplete>
                        </v-col>

                        <!--<v-col cols="12"
           sm="6"
           md="4">
        <v-autocomplete v-model="customerData.state"
                        :items="state"
                        label="State"
                        clearable
                        single-line
                        item-text="value"
                        item-value="id"></v-autocomplete>
    </v-col>-->
                        <!--<v-col cols="12"
           sm="6" md="4">
        <v-autocomplete v-model="customerData.customerType" :items="PassengerTypes"
                        item-value="value"
                        item-text="value"
                        label="Age Group"></v-autocomplete>
    </v-col>-->
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-autocomplete v-model="customerData.mountainName"
                                            :items="Services"
                                            :label="$t('mountain')"
                                            dense
                                              outlined
                                            item-text="value"
                                            item-value="id"></v-autocomplete>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.citizenShipNumber"
                                          :label="$t('citizenshipNumber')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.passportNumber"
                                          :label="$t('passport_number')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>

                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.licenseNumber"
                                          :label="$t('licenseNumber')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.otherID"
                                          :label="$t('otherID')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-text-field v-model="customerData.document"
                                          :label="$t('documents')"
                                          dense
                                              outlined
                                          required></v-text-field>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-switch class="col-sm-6 col-md-4"
                                      v-model="customerData.isVerifiedDocument"
                                      :label="$t('is_verified_document')"
                                      clearable></v-switch>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-switch class="col-sm-6 col-md-4"
                                      v-model="customerData.permitIssued"
                                      :label="$t('is_permit_issued')"
                                      clearable></v-switch>
                        </v-col>
                        <!-- <v-col cols="12"
            sm="6"
            md="4">
         <v-text-field v-model="customerData.issuedBy"
                       label="Issued By"
                       required></v-text-field>
     </v-col>-->
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu3"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.permitIssuedDate"
                                                  :label="$t('permit_issued_date')"
                                                  clearable
                                                  prepend-inner-icon="event"
                                                  dense
                                              outlined
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.permitIssuedDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="12"
                               sm="6"
                               md="4">
                            <v-menu v-model="snackbar.dateMenu4"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="customerData.travelDate"
                                                  :label="$t('travel_date')"
                                                  clearable
                                                  dense
                                              outlined
                                                  prepend-inner-icon="event"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="customerData.travelDate">

                                </v-date-picker>
                            </v-menu>
                        </v-col>
                        <!--
    <v-col cols="12"
           sm="6"
           md="4">
        <v-menu v-model="snackbar.dateMenu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px">
            <template v-slot:activator="{ on }">
                <v-text-field v-model="customerData.issuedDate"
                              label="Issued Date"
                              clearable
                              prepend-icon="event"
                              readonly
                              v-on="on"></v-text-field>
            </template>
            <v-date-picker v-model="customerData.issuedDate">

            </v-date-picker>
        </v-menu>
    </v-col>-->
                    </v-row>

                </v-container>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark color="red darken-1" @click="dialogueClose">{{$t('cancel')}}</v-btn>
            <v-btn dark color="blue darken-1" @click="formSubmit">{{$t('save')}}</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ClimberForm",
        data() {
            return {
                trackID:'',
                trackType:'',
                citizenshipData:[],
                passportData:[],
                dropdownData:{
                    citizenShipNumber:"",
                    passportNumber:""
                },
                isLoading: false,
                searchCitizen: null,
                searchPassport: null,

                customerData: { },
                customerFormData: [],
                message: {
                    status: true,
                    text: 'true',
                },
                snackbar: {
                    dateMenu: false,
                    dateMenu2: false,
                    dateMenu3: false,
                },
                countries: [],
                state: [],
                airList: [],
                Destinations: [],
                Services: [],
                AirPackages: [],
                PassengerTypes: [],
                FlightClasses: [],
                Issuer: ['E-Sewa'],
                payType: ["Cash", "Visa Card"],
                servicesData: {
                    data: {},
                    message: "",
                    color: ""
                }
            }
        },
        watch: {
            "dropdownData.citizenShipNumber": {
                handler: function (val) {
                    if (val) {
                        axios.get("Shared/GetPersonInformationByCitizenshipAsync",{
                            params:{
                                CitizenShipNumber: val.value
                            }
                        }).then(response=>{
                            this.customerData = response.data
                            if(response.data.country){
                                this.getState(response.data.country)
                            }
                        }).catch(err=>{
                            console.log(err)
                        });
                    }
                },
                deep: true
            },
            "dropdownData.passportNumber": {
                handler: function (val) {
                    if (val) {
                        axios.get("Shared/GetPersonInformationByPassportAsync",{
                            params:{
                                passportNumber: val.value
                            }
                        }).then(response=>{
                            this.customerData = response.data
                            if(response.data.country){
                                this.getState(response.data.country)
                            }
                        }).catch(err=>{
                            console.log(err)
                        });
                    }
                },
                deep: true
            },
            searchCitizen(val) {

                if (val) {
                    this.items = []
                    // Items have already been loaded
                    if (this.items.length > 0) return

                    // Items have already been requested
                    if (this.isLoading) return

                    this.isLoading = true

                    // Lazily load input items

                    axios.get("Track/GetCitizenIDListAsync", {
                        params: {
                            CitizenID: val
                        }
                    })
                        .then(res => {
                            this.citizenshipData = res.data
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally(() => (this.isLoading = false))
                }
            },
            searchPassport(val) {

                if (val) {
                    this.items = []
                    // Items have already been loaded
                    if (this.items.length > 0) return

                    // Items have already been requested
                    if (this.isLoading) return

                    this.isLoading = true

                    // Lazily load input items
                    axios.get("Track/GetPassportIDListAsync", {
                        params: {
                            PassportNumber: val
                        }
                    })
                        .then(res => {
                            this.passportData = res.data
                        })
                        .catch(err => {
                            console.log(err)
                        })
                        .finally(() => (this.isLoading = false))
                }
            },
        },
        computed: {},
        methods: {

            getUserData() {
                if (this.trackID) {
                    this.userInfo = []
                    axios.get('Track/GetPersonInformationByID', {
                        params: {
                            ID: this.trackID,
                            Type: this.trackType
                        }
                    }).then(response => {
                        console.log('mmm', response.data)
                        this.customerData.firstName = response.data.firstName
                        this.customerData.fullName = response.data.fullName
                        this.customerData.nationality = response.data.nationality
                        this.customerData.lastName = response.data.lastName
                        this.customerData.country = response.data.country
                        this.customerData.dateOfBirth = response.data.dateOfBirth
                        this.customerData.dateOfBirths = response.data.dateOfBirths
                        this.customerData.state = response.data.state
                        this.customerData.gender = response.data.gender
                        this.customerData.citizenShipNumber = response.data.citizenShipNumber
                        this.customerData.passportNumber = response.data.passportNumber
                        this.customerData.passportNumber = response.data.passportNumber
                        this.customerData.licenseNumber = response.data.licenseNumber
                        if (response.data.country) {
                            this.getState(response.data.country)
                        }
                        this.trackID = ""
                    })
                }
            },

            dialogueClose() {
                console.log(this.servicesData)
                this.$emit("formResponse", this.servicesData);
            },
            formSubmit() {
                this.customerData.IsImportFromExcel = false
                this.customerData.paidAmount = parseInt(this.customerData.paidAmount)
                axios.post('Mountaining/InsertIntoCustomerInformationAsync', this.customerData)
                    .then(response => {
                        if (response.data.success) {
                            this.editedData = {};
                            this.servicesData.data = response.data
                            this.servicesData.message = "Customer added succcessfully"
                            this.servicesData.color = "success"
                            this.dialogueClose()
                        }
                    })
                    .catch(response => {
                        console.log(response.data)
                        this.message.status = false
                        this.message.text = 'Error Adding Service, Please Contact Admin'
                    })
            },
            async getState(val) {
                const stateList = await axios.get('Shared/StateListByCountryCode?CountryCode=' + val);
                this.state = stateList.data
            },
            formData() {
                axios.get("Shared/CountryList").then(response => {
                    this.countries = response.data;
                }).catch(error => console.log(error.response))
                axios.get("Mountaining/DDLMountainByIDAsync").then(response => {
                    this.Services = response.data;
                }).catch(error => console.log(error.response))
            },
            changePaidAmount(data) {
                let paidService = this.Services.filter(service => service.id == data[0])
                this.customerData.paidAmount = paidService[0].amount
            }
        },
        mounted() {
            this.formData();
        },
    }
</script>

<style scoped>

</style>